<template>
<div>

<section id="TagLine" class="ma-0">
<v-row>
<v-col cols="12" sm="12" md="6" class="ma-0 pa-0">
<v-carousel cycle delimiter-icon="mdi-minus" hide-delimiter-background show-arrows-on-hover>
<v-carousel-item class="animated fadeIn">
  <v-sheet color="pink" height="100%" tile class="pa-5">
    <v-row class="fill-height" align="center" justify="center">
      <div class="display-2">
        Something one <br> can offer to his child as an asset is the best education!
      </div>
    </v-row>
  </v-sheet>
</v-carousel-item>

<v-carousel-item class="animated fadeIn">
  <v-sheet color="teal" height="100%" tile class="pa-5">
    <v-row class="fill-height" align="center" justify="center">
      <div class="display-2">
        Ensure your <br> children think intensively and creatively through proper education!
      </div>
    </v-row>
  </v-sheet>
</v-carousel-item>

<v-carousel-item class="animated fadeIn">
  <v-sheet color="indigo" height="100%" tile class="pa-5">
    <v-row class="fill-height" align="center" justify="center">
      <div class="display-2">
        Defeat all your <br> barriers to success through the robust way of learning!
      </div>
    </v-row>
  </v-sheet>
</v-carousel-item>

<v-carousel-item class="animated fadeIn">
  <v-sheet color="accent" height="100%" tile class="pa-5">
    <v-row class="fill-height" align="center" justify="center">
      <div class="display-2">
        Improve your <br> personality and communication skills to win an exciting career!
      </div>
    </v-row>
  </v-sheet>
</v-carousel-item>

<v-carousel-item class="animated fadeIn">
  <v-sheet color="deep-purple" height="100%" tile class="pa-5">
    <v-row class="fill-height" align="center" justify="center">
      <div class="display-2">
        We provide a <br> family-friendly environment for any student to feel home!
      </div>
    </v-row>
  </v-sheet>
</v-carousel-item>

</v-carousel>
</v-col>
<v-col cols="12" sm="12" md="6" class="pa-0 teal lighten-4 d-flex justify-center">
  <div id="DoYouKnow" class="lime lighten-3 pa-5 d-flex justify-center" style="flex-direction: column">
    <p class="display-1 my-5 pl-5">What's behind The Logo?</p>
    <p class="pa-5 title">
      The entire form of the logo depitcs a traditional and divine instrument called “VEENA” 
      which is used by the Hindu Godess of knowledge, music, art, wisdom, and learning named Saraswati.
      The idea is to show an aesthetical logo which shows the fusion between tradition and mordern education.
      The two dots in the logo represents the teacher and the student, “K” is an abstract form of the teacher 
      performing or teaching art and “Y” being the student who receives it from the teacher and that’s the reason 
      behind “K” and “Y” are connected.
    </p>
  </div>
</v-col>
</v-row>
</section>


</div>
</template>

<script>
export default {
    name: 'tagline'
}
</script>

<style scoped>
#TagLine .v-carousel .display-2{
  padding: 5vw !important;
}
</style>